<template>
  <div class="login">
    <div class="content content--no-tabs">
      <form action="https://formsubmit.co/info@nordic-minds.com" method="POST" accept-charset="UTF-8">
        <input type="hidden" name="_captcha" value="false">
        <input type="hidden" name="_template" value="table">
        <input type="hidden" name="_next" value="https://nordic-minds.com/thankyou">
        <div class="container grid-md">
          <div class="columns">
            <div class="column col-11 col-sm-12">
              <h1>{{ $t('contact.headline') }}</h1>
              <div v-html="$t('contact.text')" />
              <div class="columns">
                <div class="column col-12">
                  <label for="type_company" class="radio">
                    <input type="radio" name="type" id="type_company" value="Companies"/>
                    <span>{{ $t('navigation.companies') }}</span>
                  </label>
                  <a href="mailto:application@nordic-minds.com">
                    <label for="type_candidate" class="radio">
                      <!-- <input type="radio" name="type" id="type_candidate" value="Candidates"/> -->
                      <span>{{ $t('navigation.candidates') }}</span>
                    </label>
                  </a>
                </div>
              </div>
              <div class="columns">
                <div class="column col-4 col-sm-12 col-md-4">
                  <input class="input" type="text" name="name" v-bind:placeholder="$t('contact.name')" required/>
                </div>
                <div class="column col-4 col-sm-12 col-md-4">
                  <input class="input" type="email" name="email" v-bind:placeholder="$t('contact.email')" required/>
                </div>
                <div class="column col-4 col-sm-12 col-md-4">
                  <input class="input" type="tel" name="tel" v-bind:placeholder="$t('contact.phone')"/>
                </div>
                <div class="column col-12">
                  <textarea name="message" v-bind:placeholder="$t('contact.message')"></textarea>
                </div>
                <div class="column col-12">
                  <div class="form-actions">
                    <button class="button button--arrow-right" type="submit">{{ $t('contact.button') }}</button>
                    <div class="direct-contact">
                      <a href="mailto:info@nordic-minds.com">info@nordic-minds.com</a>
                      <a href="tel:+4940593621410">+49 (0) 40 5936 21410</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {},
}
</script>

<style lang="scss" scoped>
  .intro {
    padding: 2rem 0 4rem 0;
  }

  .content {
    background: $color-primary-50;
  }

  .col-5.col-sm-12.col-md-6.col-lg-5 {
    max-width: 25.8rem;
  }

  .radio {
    &:last-child {
      @media (max-width: $size-md) {
        margin-bottom: 1rem;
        display: block;
      }
    }

    input {
      display: none;
    }

    span {
      @media (min-width: $size-md) {
        margin: 1.5rem 1.5rem 2rem 0;
      }

      display: inline-block;
      color: #AAD9D5;
      border: 2px solid #AAD9D5;
      padding: 0.7rem 1.5rem;
      margin: 0 1rem 1rem 0;
      line-height: 1rem;
      font-size: 1rem;
      border-radius: 3rem;
      text-align: center;
      transition: all $transition-duration $transition-easing;
      cursor: pointer;
    }

    input:checked + span {
      color: $color-primary;
      border-color: $color-primary;
    }
  }

  .teaser {
  margin-top: 2rem;
  margin-bottom: 2rem;

    @media (min-width: $size-md + 1px) {
      margin-top: 4rem;

      &--push-down {
        margin-top: $header-wrapper;
      }
    }
  }

  .input {
    display: block;
    width: 100%;
    border: 0;
    padding: .4rem 1.5rem .6rem;
    margin: 0 0 1.5rem 0;
    line-height: 1.5rem;
    transition: $transition-duration/2 $transition-easing;
    outline: 2px solid rgba(#AAD9D5, 0);

    &:focus,
    &:active {
      outline: 2px solid rgba(#AAD9D5, 1);
    }
  }

  textarea {
    display: block;
    width: 100%;
    height: 12rem;
    border: 0;
    padding: .7rem 1.5rem .6rem;
    margin: 0 0 1.5rem 0;
    transition: $transition-duration/2 $transition-easing;
    outline: 2px solid rgba(#AAD9D5, 0);

    &:focus,
    &:active {
      outline: 2px solid rgba(#AAD9D5, 1);
    }
  }

  .form-actions {
    @media (min-width: $size-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        margin: 0;
      }
    }
  }
  .direct-contact {
    @media (min-width: $size-md) {
      margin-top: 0;
    }

    display: block;
    margin-top: 2rem;
    width: 100%;

    a {
      @media (min-width: $size-md) {
        float: right;
        margin-left: 1.5rem;
      }

      display: block;
    }
  }
</style>
